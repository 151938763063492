import { PageProps } from 'gatsby';
import { ProfessionalData } from 'packages/innedit';
import React, { FC } from 'react';

import CMSView from '~/components/View';
import Form from '~/containers/Espace/Form';
import professional from '~/params/professional.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const ProfessionalsUpdate: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  user,
  params: { espaceId, id },
}) => {
  const model = new ProfessionalData({
    espaceId,
    params: professional,
  });

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <Form
          docId={id}
          itemPathnamePrefix={`/espaces/${espaceId}/params/professionals/`}
          model={model}
          name="professional"
          type="update"
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(ProfessionalsUpdate);
